import { LitElement, html, css, customElement, property } from 'lit-element';
import { formatTime, formatTimeOfDay } from 'lib/helpers';
import { styleMap } from 'lit-html/directives/style-map.js';
import { isLocalized, t } from 'i18n';
import adTypes from 'constants/ad-types';
import config from 'config';

/**
 * cast-scrubber component
 *
 * Playback scrubber displayed in the player overlay
 *
 * @class cast-scrubber
 * @hideconstructor
 */
@customElement('cast-scrubber')
@isLocalized
class Scrubber extends LitElement {
  @property({
    type: Object
  })
  playerEvents = {
    'AD_BREAK_COMPLETE': this.onAdBreakChanged.bind(this),
    'AD_BREAK_START': this.onAdBreakChanged.bind(this)
  }

  @property({
    type: Boolean,
    reflect: true,
    attribute: 'ad-mode'
  })
  adMode = false;

  @property({
    type: Number
  })
  adBreakProgressValue = 0;

  /**
   * Scrubber position
   *
   * This is a value between 0 and 1.
   *
   * @name cast-scrubber#position
   * @type {number}
   * @default 0
   */
  @property({
    type: Number
  })
  position = 0;

  /**
   * Scrubber max
   *
   * This is a max value
   * VOD: duration
   * Linear: Duration of current program
   *
   * @name cast-scrubber#max
   * @type {number}
   * @default 0
   */
  @property({
    type: Number
  })
  max = 0;

  /**
   * Scrubber min - starting minimum value
   *
   * This is a min value
   * VOD: usually 0
   * Linear: timestamp of when program began
   *
   * @name cast-scrubber#min
   * @type {number}
   * @default 0
   */
  @property({
    type: Number
  })
  min = 0;

  @property({
    type: Boolean,
    reflect: true,
    attribute: 'is-linear'
  })
  isLinear = true;

  @property({
    type: Boolean,
    reflect: true,
    attribute: 'media-loading'
  })
  mediaLoading = true;

  @property({
    type: Object
  })
  adData = {};

  @property({
    type: Boolean,
    reflect: true,
    attribute: 'is-recording-in-progress'
  })
  isRecordingInProgress = false;

  static styles = css`
    :host {
      font-size: 3.6rem;
      font-weight: 400;
      color: var(--tv-grey-18);
    }

    /* Make opacity work on the component */
    :host > * {
      opacity: inherit;
      transition: opacity 0.25s;
    }

    .bar {
      --timeline-position: 0%;
      --timeline-height: 0.6rem;
      --indicator-height: 2.4rem;
      --indicator-width: 0.6rem;

      position: relative;
      width: 100%;
      margin-top: 4.6rem;
      height: var(--timeline-height);
      border-radius: calc(var(--timeline-height) / 2);
      background: var(--tv-grey-36);
    }

    .fill {
      background: var(--tv-blue-text-on-dark-background);
      height: var(--timeline-height);
      width: var(--timeline-position);
      border-radius: calc(var(--timeline-height) / 2) 0 0 calc(var(--timeline-height) / 2);
      max-width: 100%;
    }

    .fill.rogers,
    .fill.shaw,
    :host([ad-mode]) .fill,
    :host([is-linear]) .fill {
      background: var(--tv-white);
    }

    :host([is-recording-in-progress]) .fill.rogers,
    :host([is-recording-in-progress]) .fill.shaw {
      background: var(--tv-rogers-red);
    }

    :host([media-loading]) .fill {
      display: none;
    }
    :host([ad-mode]) .fill {
      display: flex;
    }

    .indicator {
      height: var(--indicator-height);
      width: var(--indicator-width);
      border-radius: calc(var(--indicator-width) / 2);
      background: var(--tv-grey-18);
      position: absolute;
      top: calc(-1 * var(--indicator-height) / 2 + var(--timeline-height) / 2);
      left: var(--timeline-position);
    }

    :host([is-linear]) .indicator,
    :host([media-loading]) .indicator,
    :host([ad-mode]) .indicator {
      display: none;
    }

    .scrubber-texts {
      margin-top: 2rem;
      height: 4.8rem;
    }

    .times {
      display: flex;
    }

    :host([ad-mode]) .times,
    :host([media-loading]) .times {
      display: none;
    }

    .adBreakTicks {
      display: flex;
    }

    :host([ad-mode]) .adBreakTicks,
    :host([media-loading]) .adBreakTicks,
    :host([media-loading]) .messaging {
      display: none;
    }

    .times > * {
      flex-grow: 1;
    }

    .duration {
      text-align: right;
    }

    .messaging {
      width: 100%;
      left: -0.5rem;
      text-align: center;
    }

    .transport-ad-breaks {
      position: absolute;
      height: 0.7rem;
      background-color: var(--tv-white);
    }
  `;

  onAdBreakChanged() {
    const isVod = this.adData.watchable && !this.adData.watchable.isLinear();
    if (!this.adData.watchable) {
      this.min = 0;
      this.max = 0;
    } else if (this.adData.type === 'AD_BREAK_START') {
      this.min = 0;
      this.max = this.adData.videoAdBreak.duration;
    } else if (this.adData.type === 'AD_BREAK_COMPLETE') {
      this.min = isVod ? 0 : this.adData.watchable.startTime;
      this.max = isVod ? this.adData.watchable.duration : this.adData.watchable.endTime;
    }
    this.mediaLoading = false;
    this.adMode = this.adData.adsPlaying;
  }

  buildAdBreaks() {
    return ((this.adData && this.adData.timelineAdBreaks) || []).map((ad) => {
      const assetDuration = this.max;
      const adWidth = this.adData.adType === adTypes.freewheel ? 0.5 : ((ad.duration / assetDuration) * 100);
      // get percentage
      const startPosition = (ad.startTime /assetDuration ) * 100;
      // this is to offset the thumb scrubber to not begin an ad break until closer into the dot graphic
      const thumbnailOffset = startPosition - 0.1;
      const style = {
        left: thumbnailOffset + '%',
        width: (adWidth > 0 ? adWidth : 0.5) + '%'
      };
      if (!ad.startTime) {
        return html``;
      }
      return html`<div class="transport-ad-breaks" style=${styleMap(style)}/>`;
    });
  }
  updateAdData(adData) {
    this.adData = adData;
  }

  getAdCountText() {
    const curAd = this.adData.currentAd;
    const curAdLength = this.adData.currentAdBreak.videoAdBreak.ads.length;
    if (curAdLength > 1) {
      return html` ${ t('adPosition of totalAds ads', {
        adPosition: curAd.videoAd.index + 1,
        totalAds: curAdLength
      } )}`;
    }
    return '';
  }

  getAdText(totalMSecs) {
    let mins;
    let secs = Math.round((this.adData.duration - totalMSecs) / 1000);
    const adCountText = this.getAdCountText();
    if (secs > 0 && secs < 60) {
      return html` ${ t('Your video will resume in sec', { secs: secs }) } ${adCountText}`;
    } else if (secs >= 60) {
      mins = parseInt(secs / 60, 10);
      secs = parseInt(secs % 60, 10);
      return html` ${ t('Your video will resume in min sec', { mins: mins, secs: secs }) } ${adCountText} `;
    }
    return html` ${ t('Your video will resume shortly') } `;
  }

  getMessaging() {
    if (this.adMode && this.adData.currentAdBreak) {
      return this.getAdText(this.adData.currentAdBreak.position);
    }
  }

  getTimes() {
    if (typeof this.min === 'string') {
      this.min = new Date(this.min).getTime();
    }
    return this.min > 0 ? this.renderLinearTimes() : this.renderTimes();
  }

  updated() {
    let ratio = 0;
    ratio = Math.min(1, Math.max(0, (this.position - this.min) / (this.max - this.min)));
    !isNaN(ratio) && this.shadowRoot.querySelector('.bar').style.setProperty('--timeline-position', (ratio * 100) + '%');
  }

  renderLinearTimes() {
    if (this.adMode) {
      return this.renderTimes();
    }
    return html`
      <div class="currentTime">${ formatTimeOfDay(this.min) }</div>
      <div class="duration">${ formatTimeOfDay(this.max) }</div>
    `;
  }

  renderTimes() {
    return html`
      <div class="currentTime">${ formatTime(this.position) }</div>
      <div class="duration">${ formatTime(this.max) }</div>
    `;
  }

  render() {
    return html`
      <div class="bar">
      ${ !this.adMode ? html`
        <div id = 'adBreakTicks' class="adBreakContainer">${ this.buildAdBreaks() }</div>
      ` : null }
        <div class="fill ${ config.partner }"></div>
        <div class="indicator"></div>
      </div>
      <div class="scrubber-texts">
        <div class="times">${ this.getTimes() }</div>
        <div class="messaging">${ this.getMessaging() }</div>
      </div>
    `;
  }
}

export default Scrubber;
